import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row, Text, SubTitle, Button, Title, Container, OnlyMobile, NotMobile} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';
import { Link } from 'react-router-dom';

const Pp = ()=>{
    const settings = useSelector(state => state.data.settings);

    useEffect(()=>{
        
    })

    if (!settings){
        return null;
    }

    return <>
    
    
    <MetaTags>
                 <title>{ `LAURA LEMAITRE - tos`}</title>
                 {/* <meta name="description" content={BlocktoPlainText(settings.intro)} /> */}
                <meta property="og:title" content={"LAURA LEMAITRE"} />
                {/* <meta property="og:image" content={settings.url+"?w=720"} />   */}
            </MetaTags>
            <div className="azerty">
            <Container>
                <BlockContent blocks={settings.pp} />
            </Container>
            <br/><br/><br/><br/>
            </div>
            
    
    </>
  }

export default Pp;