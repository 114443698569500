import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row, Text, SubTitle, Button, Title, Container, NotMobile, OnlyMobile} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import BasketVisualisation from './basketvisualisation';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';



import ModalImage from "react-modal-image";

Modal.setAppElement('#root');



const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: "solid black 10px",
      padding: "0px",
      background: "black",
      maxWidth: "80vw",
      maxHeight: "80vh"
    },
    overlay: {
        background: "rgba(0, 0, 0, 0)"
    }
  };

const Shop = ()=>{
    const settings = useSelector(state => state.data.settings);
    const basket = useSelector(state=>state.data.basket);
    const [selected, setSelected] = useState(0);
    const [hover, setHover] = useState(null);
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalimage, setmodalimage] = useState(null);
    

    function openModal(modalimage) {
        setmodalimage(modalimage);
        setIsOpen(true);
      }
    
      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
      }
    
      function closeModal() {
          setmodalimage(null);
        setIsOpen(false);
      }


    useEffect(()=>{
      
    })

    if (!settings){
        return null;
    }

    const addButton = (c, product)=>{
        const buttonWidth = "80px";

        const draw =(c1, c2)=>{
            return <svg viewBox="0 0 163.04 163.04" width={buttonWidth} style={{marginRight: "10px"}} key={c1+c2} onClick={()=>{
                const nb = [...basket];
                if (nb.length == 0 && product.title.toLowerCase() !== "strap"){
                    settings.products.forEach((pr,i)=>{
                        if (pr.title.toLowerCase() == "strap"){
                            nb.push({product: pr,color:{outline: "000", body: "000"} });
                        }
                    })
                }
                nb.push({
                    product,
                    color: c?c:{outline: "000", body: "000"}
                })
                
                dispatch({ type: DATA_ACTIONS.SET_BASKET, basket: nb});
            }} >
            <path  style={{fill: "#"+c1}}  d="M281.44,200a81.52,81.52,0,1,1-81.52-81.51A81.52,81.52,0,0,1,281.44,200" transform="translate(-118.4 -118.49)"/>
            <path  style={{fill: "#"+c2}} d="M263.68,200c0,35.21-28.55,63.76-63.76,63.76c-35.21,0-63.76-28.55-63.76-63.76c0-35.21,28.55-63.76,63.76-63.76C235.13,136.24,263.68,164.79,263.68,200" transform="translate(-118.4 -118.49)"/>
            <polygon  style={{fill: "#"+c1}} points="246.33,194.28 205.72,194.28 205.72,153.67 194.28,153.67 194.28,194.28 153.67,194.28 153.67,205.72 194.28,205.72 194.28,246.33 205.72,246.33 205.72,205.72 246.33,205.72 " transform="translate(-118.4 -118.49)"/>
        </svg>   
        }

        if (c && c.body && c.outline){
            if (c.available){
                return draw(c.body, c.outline);
            } else {
                return null;
            }
        } else {
            return draw("000", "FFFFFF")
        }
    }

    return <>
    
    <MetaTags>
                 <title>{ `LAURA LEMAITRE - shop`}</title>
                <meta property="og:title" content={"LAURA LEMAITRE"} />
                {/* <meta property="og:image" content={settings.url+"?w=720"} />   */}
            </MetaTags>
            <NotMobile>
            <div className="homeWrapper">
              <div className="shopLeft">
                  <br/><br/>
                  {settings.products.map((product, i)=>{
                      
                      const bodyColor       = (i == selected)?"black":(i == hover)?"lightgrey":"grey";
                      const outlineColor    = (i == selected)?"grey":"black";
                      const h = 100;
                      const w = "20vw";
                      const w2 = 300;
                      const h2 = 300;

                      return <div onMouseEnter={()=>{
                          setHover(i)
                      }}
                      onMouseLeave={()=>{
                          setHover(null);
                      }}
                      onClick={()=>{
                          setSelected(i)
                             
                          const el = document.getElementById('#container2');

                          if (el){
                            el.scrollTop = 0;
                          }
                          
                      }} key={i+product.title} style={{height: `calc(100vh/${settings.products.length} - 20px)`,  overflow: "hidden", cursor: "pointer"}}> 
                            {(product && product.shape && product.shape.outline)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: outlineColor, position: "absolute"}}>
                            { ReactHtmlParser (product.shape.outline) }
                            </svg> : null}
                            {(product && product.shape && product.shape.body)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: bodyColor, position: "absolute"}}>
                            { ReactHtmlParser (product.shape.body) }
                            </svg> : null}
                      </div>
                  })}
              </div>
              <div className="shopCenter"  id="#container2">
                  {(selected !== null)?
                    (()=>{
                        const p  = settings.products[selected];
                        const borderstyle = "solid black 10px";
                        return <div> 
                            <Container>
                                <br/>
                            {(p.photos && p.photos.length)?<img className="ResponsiveImage" src={p.photos[0].img} onClick={()=>{openModal(p.photos[0].img)}} /> : null}
                            <br/><br/>
                            <div className="Title" style={{float: "left"}}> {p.title} </div>
                            <div className="SubTitle" style={{float: "right", transform: "translateY(30px)"}}> €{p.price} </div>
                            
                            <div style={{float: "none", clear: "left", paddingTop: "20px"}}> 
                                <BlockContent blocks={p.description} />
                            </div>
                            <div>
                                <div className="SubTitle" style={{float: "left", width: "50%", fontSize: "42px"}}>
                                    {p.colors?"Choose color to add to bag":"Add to bag"}
                                </div>
                                <div className="SubTitle" style={{float: "left", width: "50%", }}>
                                    {p.colors?p.colors.map((c, i)=>{
                                        return addButton(c, p)
                                    }):addButton(null, p)}                                    
                                </div>
                            </div>
                            <br/><br/> &nbsp;
                            </Container>
                            <div style={{background: "black"}}> 
                            {(p.photos && p.photos.length>1)?p.photos.filter((pic, i) =>(i > 0)).map((pic, i)=><div key={"img"+i}>
                                <img  onClick={()=>{openModal(pic.img)}} src={pic.img} style={{width: "100%", borderTop: borderstyle, borderBottom: (i == (p.photos.length -2))?borderstyle:null}}/>
                                
                                </div>):null}
                            </div>
                        </div>
                    })()
                  :null}
                  
              </div>
              <div className="shopRight">
              
                  {(basket && basket.length)?<>
                  <BasketVisualisation basket={basket} />
                  <Link to="/checkout">
                  <div className="shopRightBottom SubTitle">
                     SHOPPING BAG {(basket && basket.length)?`(${basket.length})`:null}
                 </div>
                 </Link> </>: null}
                 
              </div>
            </div>
            
            <div>
      
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
      >
          {modalimage?<img src={modalimage} style={{width: "100%", height: "80vh"}} onClick={closeModal} />:null}
      </Modal>
    
    {/* {modalIsOpen? 
<ModalImage
  small={modalimage}
  large={modalimage}
  alt="Hello World!"
/>
:null} */}

    </div>
    </NotMobile>

    <OnlyMobile >
        {/* <div hello id="#div">Allo</div> */}
        <div style={{width: "100%"}}>
        {settings.products.map((product, i)=>{
                      
                      const bodyColor       = (i == selected)?"black":(i == hover)?"lightgrey":"grey";
                      const outlineColor    = (i == selected)?"grey":"black";
                      const h = 100;
                      const w = "15vw";
                      const w2 = 300;
                      const h2 = 300;

                      return <div onMouseEnter={()=>{
                          setHover(i)
                      }}
                      onMouseLeave={()=>{
                          setHover(null);
                      }}
                      onClick={()=>{
                          setSelected(i)
                             
                          const el = document.getElementById('#container');

                          if (el){
                            el.scrollTop = 0;
                          }
                          


                      }} key={i+product.title} style={{height: `calc(100vh/${settings.products.length} - 20px)`,  overflow: "hidden", cursor: "pointer", float: "left", width: `calc(100vw / ${settings.products.length} - 0px)`, position: "relative", borderBottom: "solid 10px black"}}> 
                            {(product && product.shape && product.shape.outline)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: outlineColor, position: "absolute"}}>
                            { ReactHtmlParser (product.shape.outline) }
                            </svg> : null}
                            {(product && product.shape && product.shape.body)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: bodyColor, position: "absolute"}}>
                            { ReactHtmlParser (product.shape.body) }
                            </svg> : null}
                      </div>
                  })}
        </div>

        <div id="#container" className="azerty"> 
                  
        {(selected !== null)?
                    (()=>{
                        const p  = settings.products[selected];
                        const borderstyle = "solid black 10px";
                        return <div > 
                            {(p.photos && p.photos.length)?<img className="ResponsiveImage" style={{backgroundColor: "black",  borderBottom: borderstyle}} src={p.photos[0].img} onClick={()=>{openModal(p.photos[0].img)}} /> : null}
                            <Container>
                            <div className="Title" style={{float: "left"}}> {p.title} </div>
                            <div className="SubTitle" style={{float: "right", transform: "translateY(30px)"}}> €{p.price} </div>
                            
                            <div style={{float: "none", clear: "left", paddingTop: "10px"}}> 
                                <BlockContent blocks={p.description} />
                            </div>
                            <div>
                                <div className="SubTitle" style={{width: "100%", fontSize: "30px"}}>
                                    {p.colors?"Choose color to add to bag":"Add to bag"}
                                </div>
                                <br/>
                                <div className="SubTitle" style={{float: "left", width: "100%", }}>
                                    {p.colors?p.colors.map((c, i)=>{
                                        return addButton(c, p)
                                    }):addButton(null, p)}                                    
                                </div>
                            </div>
                            <br/><br/> &nbsp;
                            </Container>
                            <div style={{background: "black"}}> 
                            {(p.photos && p.photos.length>1)?p.photos.filter((pic, i) =>(i > 0)).map((pic, i)=><div key={"img"+i}>
                                <img  src={pic.img} style={{width: "100%", borderTop: borderstyle, borderBottom: (i == (p.photos.length -2))?borderstyle:null}}/>
                                
                                </div>):null}
                            </div>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    })()
                  :null}
                  </div>

        <Link to="/checkout">
                    <div className="mHomeBottom SubTitle grow" style={{paddingTop: "10px"}}>
                    SHOPPING BAG {(basket && basket.length)?`(${basket.length})`:null}
                  </div>
              </Link> 

    </OnlyMobile>
                    
    </>
  }

export default Shop;