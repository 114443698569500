import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
// import toast from '../services/toast'
import imageUrlBuilder from '@sanity/image-url'
import { customAlphabet } from 'nanoid'
import blockTools from '@sanity/block-tools';
import Schema from '@sanity/schema';


const prevFetched = {};

export const client = (dispatch)=>{
    const nanoid = customAlphabet('1234567890abcdef', 5);
    const environment = envi();
    let _ = settings;
    let news = [];
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: false
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    
    if (environment.dev){
        environment.printstatus()
    } 

    const getUrl = (selector, name)=>{
        name = name || "img";
        return `"${name}" : ${selector}.asset->url`
    }

    const settingsQuery = `*[_type == "siteSettings"][0]{
        about, ${getUrl("aboutPhoto", "aboutPhoto")}, aboutphotos[]{
            enableMobile, ${getUrl("img")}
        }, lookbook[]{
            enableMobile, ${getUrl("img")}
        }, title, tos, pp, products[]->{
            title,
            description,
            price,
            shape,
            colors,
            photos[]{
                "img" : asset->url
            }
        }
    }`;
    
    fetch(settingsQuery)
    .then(settings=>{
        setTimeout(()=>{
            console.log(settings);
        }, 500)

        settings.lookbook = [...settings.lookbook].concat([...settings.lookbook])

        dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings});
    })

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const defaultSchema = Schema.compile({
        name: 'myBlog',
        types: [
          {
            type: 'object',
            name: 'blogPost',
            fields: [
              {
                title: 'Title',
                type: 'string',
                name: 'title'
              },
              {
                title: 'Body',
                name: 'body',
                type: 'array',
                of: [{type: 'block'}]
              }
            ]
          }
        ]
      })

      const blockContentType = defaultSchema.get('blogPost')
  .fields.find(field => field.name === 'body').type

    const updateMeme = (_id, display)=>{
        sc.patch(_id).set({display}).commit().then(()=>{
            // window.location.reload();
        })
    }

    const getEmail = (name, id)=>{
        return `Dear   ${name}, <br/><br/>

        Thank you for your support and your order #${id} 
        to finalize your order we need you to transfer the money on our
        bank account : BE83 0018 9184 1015.
        Make sure to mention your order number. <br/> <br/>

        While you are dreaming of strolling with your LAURA LEMAITRE, we will do our best to send out your order to you as soon as possible. 
        Keep an eye on your inbox for tracking information. <br/><br/>
        
        If you have any further questions don’t hesitate to check out our Terms & Conditions (met dan link eronder naar je terms & conditions pagina) <br/><br/>
        
        
        And of course a big thanks for supporting me, <br/><br/>
        
        Kind Regards, <br/>
        Laura Lemaitre <br/>
        <a href="https://www.instagram.com/lauralemaitre_/">@lauralemaitre_</a>
        `
    }

    const getEmail2 = (name, id)=>{
        return `Dear  ${name}, <br/><br/>
        Thank you for your transaction. <br/> <br/>
        Your package is on the way, below you will find a link where you can keep an eye on your LAURALEMAITRE goods. 
        <br/><br/>
        Kind Regards, <br/>
        Laura Lemaitre <br/>
        <a href="https://www.instagram.com/lauralemaitre_/">@lauralemaitre_</a>
        `
    }

    const getEmail3 = (name, id)=>{
        return `
        Dear  ${name}, <br/><br/>
        Your LAURALEMAITRE has arrived :) <br/>
        track and trace link <br/>
        Have fun and don’t hesitate to send us some pictures.    <br/><br/>
        
        If you're not happy about our product we have a return policy of 14 day.<br/>
        Don’t hesitate to contact us if you have further questions. <br/>
        <br/>
        Kind Regards, <br/>
        Laura Lemaitre <br/>
        <a href="https://www.instagram.com/lauralemaitre_/">@lauralemaitre_</a>
        `
    }


    const placeOrder = (email, surname, name, street, number, city, zipcode, country, remarks, price, products)=>{
        const orderID =  nanoid();
        const doc = {
            _type: 'order',
            price,
            email: blockTools.htmlToBlocks(getEmail(name, orderID),   blockContentType),
            email2: blockTools.htmlToBlocks(getEmail2(name, orderID),   blockContentType),
            email3: blockTools.htmlToBlocks(getEmail3(name, orderID),   blockContentType),
            status: "unprocessed",
            orderID,
            customer: {
                email,
                surname,
                name,
                street,
                number,
                city,
                zipcode,
                country : country || "no country submitted",
                remarks : remarks || "no remarks"
            },
            products: products.map(({product, color}, i)=>{
                return {
                    productname: product.title,
                    colorname: color.title,
                    price: product.price,
                    _key: nanoid()
                }
            })
          }
          
          sc.create(doc).then((res) => {
            console.log(`order was processed ${res._id}`)
          })
    }



    // about
    // fetch('*[_type == "siteSettings"][0]{about, title, "default":default.asset->url}').then((settings)=>{
    //   dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings});
    // })

    // fetch('*[_type == "portfolio"][0]{projects[]->{title, "slug":slug.current, description, "cover":cover.asset->url, "images":images[].asset->url}}').then(({projects})=>{
    //     dispatch({type: DATA_ACTIONS.SET_PROJECTS, projects});
    // })

    // title, description, 
    // fetch("*[_type == 'biomoddnews']{title, description, images[]{title, description, 'image':image.asset->url}} | order(_createdAt desc)").then((news)=>{
    //     console.log(news)
    //     dispatch({ type: DATA_ACTIONS.SET_NEWS, news});
    // })

    

    return {
        fetch,
        environment,
        squareImage,
        placeOrder
    }
}



export default client;

