import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import { Link, useHistory } from "react-router-dom"
import {Row, Col, Text, SubTitle, Button, Container, Title} from '../theme';
import BlockContent from '@sanity/block-content-to-react';
import ImageDrop from './imagedrop';
import PhotoBanner from './PhotoBanner';
import HomeAnimation from './homeAnimation';


const Home = ()=>{
  const [settings, menu, client] = useSelector(state => [state.data.settings, state.data.menu, state.data.client]);
  let history = useHistory();
    useEffect(()=>{
    })

    if (!settings || menu){
      return null;
    }
    
    return <div style={{overflow:"hidden"}}>
        <MetaTags>
                 <title>{ `LAURA LEMAITRE / HOME`}</title>
                 {/* <meta name="description" content={BlocktoPlainText(settings.intro)} /> */}
                <meta property="og:title" content="LAURA LEMAITRE / HOME" />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>
            {/* REGULAR */}
            <div className="NotMobile">
            <div className="homeWrapper">
              <div className="homeLeft">
                <div className="homeTop">
                <Link to="/lookbook" rel="noopener noreferrer" >
                  <PhotoBanner imgs={settings.lookbook.map(i=>i.img)} />
                  <div className="homeTopText">
                    <Title>
                      LOOKBOOK
                    </Title>
                  </div>
                  </Link>
                </div>
                <div className="homeBottom" onClick={()=>{
                  history.push("/about");
                }}>
                  
                  <img src={settings.aboutPhoto} className="homeBottomImage" />
                  <div className="homeBottomText">
                  <Title>
                    ABOUT
                  </Title>
                  </div>
                  
                </div>
              </div>
              <div className="homeRight">
                <HomeAnimation shapes={settings.products} />
              </div>
            </div>
            </div>


            {/* MOBILE */}
            <div className="OnlyMobile" >
            <div style={{width: "100%", height: "50vh", borderBottom: "solid black 10px"}}>
                <HomeAnimation shapes={settings.products} mobile />
            </div>
              <div style={{width: "100%"}}>
                <Link to="/lookbook" rel="noopener noreferrer" >
                  <PhotoBanner imgs={settings.lookbook.map(i=>i.img)} />
                </Link>
              </div>
              
              
              <Link to="/about">
                    <div className="mHomeBottom Title" style={{paddingBottom:"10px"}}>
                      About
                  </div>
              </Link> 

            </div>


            
            
            

            
    </div>
  }

export default Home;