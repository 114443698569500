export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_SETTINGS: Symbol('data/settings'),
    SET_MEMES: Symbol("data/memes"),
    SET_MENU: Symbol("ui/menu"),
    SET_BUILD: Symbol("settings/build"),
    SET_BASKET: Symbol("ui/basket")
});

const initialState = {
  client: null,
  settings: null,
  memes: null,
  menu: false,
  build: null,
  basket: []
};



export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings
      };
    }
    case ACTIONS.SET_MEMES: {
      return {
        ...state,
        memes: action.memes
      };
    }
    case ACTIONS.SET_MENU: {
      return {
        ...state,
        menu: action.menu
      }
    }
    case ACTIONS.SET_BUILD: {
      return {
        ...state,
        build: action.build
      }
    }
    case ACTIONS.SET_BASKET: {
      return {
        ...state,
        basket: action.basket
      }
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
