import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Container, NotMobile, OnlyMobile, SubTitle, Title} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';
import ReactHtmlParser from 'react-html-parser'; 
import cross from "../assets/graphics/cross/remove.svg";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer";
import LineInput from './LineInput';
import { Link } from 'react-router-dom';
import client from '../client';

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          email: null,
          surname: null, 
          name: null,
          street: null,
          number: null,
          country: null,
          remarks: null,
          city: null,
          zipcode: null
        };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.defaultStyle = { 
          width: "100%", 
          borderBottom: "solid black 10px",
          marginTop: "20px"
        }
    }
  
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });

    }
  
    handleSubmit(event) {
      event.preventDefault();
    }

    render() {
      return (
        <form id="orderform" onSubmit={this.handleSubmit} style={{width: "100% !important", height: "auto"}}>
            {["E-mail", "Surname", "Name", "Street", "Number", "City", "Zipcode", "Country"].map((v, i)=>{
                const name = v.toLowerCase().replace("-", "")
                return <label key={name}>
                <input type="text" className="SubTitle" style={this.defaultStyle} name={name} value={this.state[name]} onChange={this.handleChange} placeholder={v} />
            </label>})}
            <label>
                <br/><br/>
                <textarea rows={3} style={{fontSize: "30px", fontFamily: "Raleway", ...this.defaultStyle}} placeholder="Remarks" onChange={this.handleChange} name="remarks"/>
            </label>
            <br/>
            <br/>
            <Link to="/pp" style={{textDecoration: "underline"}}>
                Privacy policy,&nbsp;
            </Link>

            <Link to="/tos" style={{textDecoration: "underline"}}>
                Shipping policy
            </Link>
          <br/>
        </form>
      );
    }
  }

const Checkout = ()=>{
    const settings = useSelector(state => state.data.settings);
    const client = useSelector(state=>state.data.client)
    const basket = useSelector(state=>state.data.basket);
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const formRefM = useRef(null);
    const [notification, setNotification] = useState(null);

    const order = (formRef)=>{

        if (formRef && formRef.current && formRef.current.state){
            
            (({surname, email, name, street, number, city, zipcode, country, remarks})=>{
                const fixed = surname && name && street && email && city;
                if (!fixed){
                    alert("Please enter a valid email, surname, name, street, housenumber, city and country ");
                } else {
                    setNotification("Your order has been submitted, keep an eye out in your mailbox for confirmation and payment detail");
                    client.placeOrder(email, surname, name, street, number, city, zipcode, country, remarks, getTotal(), [...basket]);
                    dispatch({ type: DATA_ACTIONS.SET_BASKET, basket: []});
                }
            })(formRef.current.state)
        }
    }
    
    const clicker = (ref)=>{
        // check if we have strap
        var hasStrap = false;
        basket.forEach((b)=>{
            if (b.product.title.toLowerCase() == "strap"){
                hasStrap =true;
            }
        })

     

        if (!hasStrap){
            if (window.confirm("You are about to make an order without a strap. A strap is necessary to connect and wear the bags, you can connect multiple bags to one strap, or wear all the bags with their own strap. Press cancel to cancel the order")){
                order(ref);
            } else {

            }
        } else {
            order(ref);
        }
    }

    useEffect(()=>{
        console.log(basket)
        
    })

    if (!settings){
        return null;
    }

    const getTotal = ()=>{
        return (basket || []).reduce((previous, current)=>{
            return previous+current.product.price;
        }, 0)
    }

    return <>
    
    
    <MetaTags>
                 <title>{ `LAURA LEMAITRE - basket`}</title>
                 {/* <meta name="description" content={BlocktoPlainText(settings.intro)} /> */}
                <meta property="og:title" content={"LAURA LEMAITRE / Basket"} />
                {/* <meta property="og:image" content={settings.url+"?w=720"} />   */}
            </MetaTags>
            <NotMobile>
            <div className="homeWrapper">
                <div className="homeLeft" style={{overflowY: "scroll"}}>
                    <div style={{overflowY: "scroll", height: "calc(100vh - 120px)"}}>
                        {notification?<div>
                            <Title>
                            {notification}
                            </Title>
                            <br/>
                            <Link to="/">
                            <SubTitle>
                                Go home
                            </SubTitle>
                            </Link>
                        </div>:null}
                        <Container>
                            {basket.map((p, i)=>{
                                const h = 100;
                                const w = 100;
                                const w2 = 300;
                                const h2 = 300;
                                const product = p.product;
                                const outlineColor = "#"+p.color.outline;
                                const bodyColor = "#"+p.color.body;
                                return <div key={"checkout" +i} style={{clear: "left"}}>
                                    <br/>
                                    <div style={{position:"relative", width: "50px !important", float: "left"}}> 
                                        {(product && product.shape && product.shape.outline)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: outlineColor, position: "absolute"}}>
                                        { ReactHtmlParser (product.shape.outline) }
                                        </svg> : null}
                                        {(product && product.shape && product.shape.body)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: bodyColor, position: "absolute"}}>
                                        { ReactHtmlParser (product.shape.body) }
                                        </svg> : null}
                                        &nbsp;
                                    </div>
                                    <div style={{float: "left", marginLeft: "150px"}}> 
                                        <Title>
                                            {p.product.title}
                                        </Title>
                                        {p.color.title}
                                    </div>
                                    <div style={{float: "right"}} onClick={()=>{
                                        const nb = [...basket];
                                        nb.splice(i, 1);
                                        dispatch({ type: DATA_ACTIONS.SET_BASKET, basket: nb});
                                    }}>
                                        <img src={cross} style={{width: "70px", transform: "scale(2)"}}/>
                                    </div>
                                    <div style={{float: "right"}}> 
                                        <SubTitle>
                                        € {product.price}
                                        </SubTitle>
                                    </div>
                                    <div style={{width: "100%",  clear: "left", borderBottom: (i<basket.length-1)?"solid 10px black":null}}>
                                        &nbsp; <br/>
                                    </div>
                                        
                                    </div>
                            })}

                        </Container>
                    </div>
                <div className="checkoutBottom SubTitle">
                     TOTAL : €{getTotal()}
                 </div>
                </div>
                <div className="homeRight">
                <div style={{overflowY: "scroll", height: "calc(100vh - 120px)"}}>
                    <Container>
                        <Form ref={formRef} />
                    </Container>
                </div>
                <div className="checkoutBottom SubTitle" onClick={ ()=>{clicker(formRef)} } >
                     PLACE ORDER
                 </div>
                </div>
            </div>
            </NotMobile>

            <OnlyMobile>
            <div style={{overflowY: "scroll", height: "calc(100vh - 120px)"}}>
                        {notification?<div>
                            <Title>
                            {notification}
                            </Title>
                            <br/>
                            <Link to="/">
                            <SubTitle>
                                Go home
                            </SubTitle>
                            </Link>
                        </div>:null}
                        <Container>
                            {basket.map((p, i)=>{
                                const h = 100;
                                const w = 100;
                                const w2 = 300;
                                const h2 = 300;
                                const product = p.product;
                                const outlineColor = "#"+p.color.outline;
                                const bodyColor = "#"+p.color.body;
                                return <div key={"checkout" +i} style={{clear: "left"}}>
                                    <br/>
                                    <div style={{position:"relative", width: "50px !important", float: "left"}}> 
                                        {(product && product.shape && product.shape.outline)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: outlineColor, position: "absolute"}}>
                                        { ReactHtmlParser (product.shape.outline) }
                                        </svg> : null}
                                        {(product && product.shape && product.shape.body)?<svg viewBox={`0 0 ${w2} ${h2}`} height={h} width={w} style={{fill: bodyColor, position: "absolute"}}>
                                        { ReactHtmlParser (product.shape.body) }
                                        </svg> : null}
                                        &nbsp;
                                    </div>
                                    <div style={{float: "left", marginLeft: "150px"}}> 
                                        <Title>
                                            {p.product.title}
                                        </Title>
                                        <SubTitle>
                                        {p.color.title}
                                        </SubTitle>
                                    </div>
                                    
                                    
                                    <div style={{paddingTop: "120px"}}> 
                                        <div>
                                        
                                        <Title>
                                        € {product.price}
                                        </Title>
                                        </div>
                                        <div style={{float: "right"}} onClick={()=>{
                                        const nb = [...basket];
                                        nb.splice(i, 1);
                                        dispatch({ type: DATA_ACTIONS.SET_BASKET, basket: nb});
                                    }}>
                                        <img src={cross} style={{width: "70px", transform: "scale(2) translateY(-30px)"}}/>
                                    </div>
                                        
                                    </div>
                                    <br/>
                                    <div style={{width: "100%",  clear: "left", borderBottom: (i<basket.length-1)?"solid 10px black":null}}>
                                        &nbsp; <br/>
                                    </div>
                                        
                                    </div>
                            })}

                        <Form ref={formRefM} />

                        </Container>
                    </div>

            
                    <div className="mHomeBottom SubTitle grow" style={{paddingTop: "10px", paddingBottom: "20px"}} onClick={ ()=>{clicker(formRefM)} }>
                    TOTAL : €{getTotal()}

                    <br/>

                    PLACE  ORDER
                  </div>

              
            </OnlyMobile>
            
    
    </>
  }

  export default Checkout;

  