import React from 'react'
import { useSelector } from "react-redux";



import {Grid, Row, Col, Button, Title, SubTitle, Container, Text} from "../index.js";



export default ()=>{
    // const [theme, themes] = useSelector(state => [state.data.theme, state.data.themes]);
    const memes = useSelector(state=>state.data.memes)
    const action = ()=>{console.log("hello")};

    return <div>
            <Container> 
                <Title inverted>
                    I am Title
                </Title>
                <SubTitle inverted>
                    I am subtitle
                </SubTitle>
                <Text inverted>
                    I am regular atext
                </Text>
            </Container>
            
            <Button text="I am a redirect button" url="/" redirect />
            <Button text="I am an external button" url="http://www.google.com" external />
            <Button text="I am an action" noUrl action={action} />
        <div>
            <Title> Hello </Title>
            <SubTitle >
                    I am subtitle
                </SubTitle>
                <Text >
                    I am regular atext
                </Text>
        </div>
        <div>
            <img src="https://via.placeholder.com/150" className="ResponsiveImage" />
        </div>

        {memes?<div>found {memes.length} memes </div> : null}

    </div>
}