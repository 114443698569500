import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import {Title} from '../theme';
import ReactHtmlParser from 'react-html-parser'; 
import { render } from 'react-dom';


const BasketVisualisation = ({basket})=>{
    // const [renderShapes, setRenderShapes] = useState(null);

    // const getRandomShape = ()=>{
    //   if (!shapes){
    //     return null;
    //   }

    //   let c = shapes[Math.floor(Math.random()*shapes.length)];
      
    //   if (!(c.shape && c.shape.body && c.shape.outline && c.colors.length)){
    //     return null;
    //   }
      
      

    //   return {
    //     shape: c.shape,
    //     color: c.colors[Math.floor(Math.random()*c.colors.length)],
    //     x: Math.random(),
    //     y: Math.random()
  
    //   }
    // }

    // useEffect(()=>{
      
      

    //   const interval = setInterval(() => {
    //     if (!renderShapes && shapes){
    //       const c = getRandomShape();
    //       if (c !== null){
    //         setRenderShapes([getRandomShape()])
    //       }
    //     } else if (renderShapes && renderShapes.length > 20){
    //       const c = [...renderShapes];
    //       c.shift();
    //       setRenderShapes(c)
    //     } else if (renderShapes){
    //       const c = getRandomShape();
    //       if (c !== null){
    //         setRenderShapes([...renderShapes, getRandomShape()])
    //       }
    //     }


    //   }, 1234);
    //   return () => clearInterval(interval);

    // });



  return <div style={{width: "100%", height: "100%"}}>
    <Link to="/shop">
    
      {(basket && basket.length)?basket.filter(rs=>rs).map(({product, color}, i)=><div key={product.title+i} style={{
        position: "relative", transform: `translate(${i*40}px, ${i*80}px)`, marginLeft: "20px"
      }}>
      <svg height="400" width="400" style={{fill: "#"+color.outline, position: "absolute"}}>
        { ReactHtmlParser (product.shape.outline) }
      </svg>
      <svg height="400" width="400" style={{fill: "#"+color.body, position: "absolute"}}>
        { ReactHtmlParser (product.shape.body) }
      </svg>
        </div>):null}
    </Link>
  </div>
  }

export default BasketVisualisation;