import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import {Title} from '../theme';
import ReactHtmlParser from 'react-html-parser'; 
import { render } from 'react-dom';


const HomeAnimation = ({shapes, mobile})=>{
    const [renderShapes, setRenderShapes] = useState(null);

    const getRandomShape = ()=>{
      if (!shapes){
        return null;
      }

      let c = shapes[Math.floor(Math.random()*shapes.length)];
      
      if (!(c.shape && c.shape.body && c.shape.outline && c.colors.length)){
        return null;
      }
      
      

      return {
        shape: c.shape,
        color: c.colors[Math.floor(Math.random()*c.colors.length)],
        x: Math.random(),
        y: Math.random()
  
      }
    }

    useEffect(()=>{

      if (!renderShapes && shapes){
        let c = getRandomShape();
        while( c== null){
          c = getRandomShape();
        }
        setRenderShapes([getRandomShape()])
      }
      
      

      const interval = setInterval(() => {
        if (!renderShapes && shapes){
          const c = getRandomShape();
          if (c !== null){
            setRenderShapes([getRandomShape()])
          }
        } else if (renderShapes && renderShapes.length > (mobile?3:20)){
          const c = [...renderShapes];
          c.shift();
          setRenderShapes(c)
        } else if (renderShapes){
          const c = getRandomShape();
          if (c !== null){
            setRenderShapes([...renderShapes, getRandomShape()])
          }
        }


      }, 1234);
      return () => clearInterval(interval);

    });



  return <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
    <Link to="/shop">
    
      {renderShapes?renderShapes.filter(rs=>rs).map(({shape, color, x, y}, i)=><div key={shape+i} style={{
        position: "relative", transform:  mobile?`translate(calc(${x} * 100vw - 100px), calc(${y} * 13vh))`:`translate(calc(${x} * 40vw), calc(${y} * 50vh))`

      }}>
      <svg height="400" width="400" style={{fill: "#"+color.outline, position: "absolute"}}>
        { ReactHtmlParser (shape.outline) }
      </svg>
      <svg height="400" width="400" style={{fill: "#"+color.body, position: "absolute"}}>
        { ReactHtmlParser (shape.body) }
      </svg>
        </div>):null}

        <div className="homeRightText">
      <Title>
        SHOP
      </Title>
    </div>
    </Link>
  </div>
  }

export default HomeAnimation;