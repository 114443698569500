import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';



const PhotoBanner = ({imgs, fh})=>{
  return <div className="photobannerContainer">
  <div className="photobanner">
      {imgs.map((url, i)=><img src={url} key={i + url} className={fh?"fullHeight":"halfHeight"} />)}
  </div>
</div>
  }

export default PhotoBanner;