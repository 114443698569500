import React, {useEffect} from 'react';
import {OverviewPage} from './theme'
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import Store from "./Store";
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "./reducers/DataReducer"
import sanityclient from './client';
import { Grid, Col, Row} from './theme'
import settings from "./assets/settings.json"

import './assets/fonts/'
// import BuilderTheme from './services/Buildertheme'
import Home from './components/Home'
import Header from './components/header'
import About from './components/about'
import Lookbook from './components/lookbook'
import Checkout from './components/checkout';
import Shop from './components/shop';
import Tos from './components/tos';
import Pp from './components/pp';
// import Footer from './components/footer'
// import Tools from './components/Tools'
// import Nav from './components/nav'
// import Iare from './components/tools/iare.js'

// TODO: 
// -  >̶>̶ f̶i̶l̶l̶ m̶o̶c̶k̶u̶p̶
// /̶/̶ -̶ g̶e̶t̶ a̶l̶l̶ c̶o̶n̶t̶e̶n̶t̶
// /̶/̶ -̶ f̶i̶l̶l̶ c̶o̶n̶t̶e̶n̶t̶ i̶n̶ r̶e̶d̶u̶c̶e̶r̶
// -̶ l̶i̶s̶t̶ p̶a̶g̶e̶s̶
// - m̶a̶k̶e̶ h̶e̶a̶d̶e̶r̶
// - m̶a̶k̶e̶ h̶o̶m̶e̶
// - make lookbook
// - make about
// - make shopping
// - place order



const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 

    if (!data.build){
      dispatch({type: DATA_ACTIONS.SET_BUILD, build: settings.build || "stable"})
    }

    if (!data.theme && dispatch ){
      // const builder = new BuilderTheme();
      // dispatch({type: DATA_ACTIONS.SET_THEME, theme: new BuilderTheme()})
    }
  })
  return (
    null
  )
}


const App =()=>{
  const history = createBrowserHistory()
  // const theme = new BuilderTheme();

  return (
    <Provider store={Store}>
    <DataHelper />
      <Router history={history}>

        <Header />
        
        
        
        {/* <Grid style={{minHeight: "85vh"}}>
          <Row center="xs">
            <Col xs={12} sm={12} md={12} lg={12}>
              
              <Row> 
                
                <Col xs={12} sm={12} md ={9} lg={10}> */}
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/overview" component={OverviewPage} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/lookbook" component={Lookbook} />
                    <Route exact path="/checkout" component={Checkout} />
                    <Route exact path="/shop" component={Shop} />
                    <Route exact path="/tos" component={Tos} />
                    <Route exact path="/pp" component={Pp} />
                    {/* <Route exact path="/tools" component={Tools} />
                    <Route exact path="/tool/iare" component={Iare} /> */}
                    {/* <Route exact path="/vote" component={Voter} />
                    <Route exact path="/edition/:slug" component={Edition} />
                    <Route exact path="/edition" component={Edition} />
                    <Route exact path="/education" component={Education} />
                    <Route exact path="/schedule" component={Schedule} /> */}

                    <Route component={Home}/>
                  </Switch>
                {/* </Col> */}
              {/* </Row> */}
            {/* </Col> */}
          {/* </Row> */}
        {/* </Grid> */}

        {/* <Footer /> */}
        
        
      </Router>
  </Provider>
  );
}

export default App;
