import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {Row, Col, Title, SubTitle} from '../theme'
import Messages from  "./messages"
import {GiHamburgerMenu as MenuLogo} from 'react-icons/gi'
import {ImCross as CloseLogo} from "react-icons/im"
import Nav from "./nav"
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import logo from "../assets/logo.png"

const Header = ()=>{
    const [settings] = useSelector(state => [state.data.settings, state.data.menu]);
    let history = useHistory();

    const txt = <SubTitle>LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE LAURALEMAITRE</SubTitle> 


    if (!settings){
        return null;
      }
        return <div className="ContainerHeader" onClick={()=>{
            history.push("/");
        }}>
            <div className="wrapperHeader">
              <div className="marquee">
                <div>
                  {txt}
                </div>
                <div>
                {txt}
                </div>
              </div>
            </div>
        </div>        
}

export default Header;