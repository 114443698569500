import React, {useState} from 'react'
import styled from 'styled-components';

const Input = styled.input`
width: 100%;
padding: 5px;
box-sizing: border-box;
font-size: 15px;
`

export default ({title, NoBottom, value, setValue})=>{
    title = title || ''
    NoBottom = NoBottom || false;

    return <table style={{borderBottom: "solid black 10px", width: "100%"}}>
    <tr>
        {/* <td style={{width: 'auto'}}>
            <b>{title}</b>
        </td> */}
        <td style={{width: "100%", color: "grey"}}>
            <Input className="SubTitle" style={{color: "grey", fontSize: "80px"}} type="text" defaultValue={title} name="name" value={value} onChange = {e => setValue(e.target.value)} />
        </td>
        <td> &nbsp;&nbsp; </td>
    </tr>
</table>
}