import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row, Text, SubTitle, Button, Title, Container, OnlyMobile, NotMobile} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';
import { Link } from 'react-router-dom';

const About = ()=>{
    const settings = useSelector(state => state.data.settings);

    useEffect(()=>{
        
    })

    if (!settings){
        return null;
    }

    return <>
    
    
    <MetaTags>
                 <title>{ `LAURA LEMAITRE - about`}</title>
                 {/* <meta name="description" content={BlocktoPlainText(settings.intro)} /> */}
                <meta property="og:title" content={"LAURA LEMAITRE"} />
                {/* <meta property="og:image" content={settings.url+"?w=720"} />   */}
            </MetaTags>
            <NotMobile>
            <div className="homeWrapper">
              <div className="aboutLeft">
                {settings.aboutphotos.map(({enableMobile, img}, i)=>{
                  return <img src={img} style={{width: "100%", paddingBottom: "7px"}} key={img+i} />
                })}

              </div>
              <div className="aboutCenter">
                <Container>
                  <Title>
                    ABOUT
                  </Title>
                  <BlockContent blocks={settings.about} />

                  <Link to="/pp" style={{textDecoration: "underline"}}>
                    Privacy policy
                  </Link>

                </Container>

                
                
              </div>
              <div className="aboutRight">
              <a href="mailto:laura@lauralemaitre.be" target="_blank">
                <div className="aboutTop">
                  <Title>
                    <span className="centerText">
                    MAIL
                    </span>
                    
                  </Title>
                </div>
                </a>
                <a href="https://www.instagram.com/lauralemaitre_/">
                <div className="aboutTop">
                  <Title>
                    <span className="centerText">
                    INSTAGRAM
                    </span>
                  </Title>
                </div>
                </a>
              </div>
            </div>
            </NotMobile>

            <OnlyMobile>
              <div className="azerty">
            <Container>
                  <img src={settings.aboutphotos[0].img} style={{width: "100%", paddingBottom: "7px"}} />
                  <Title>
                    ABOUT
                  </Title>
                  <BlockContent blocks={settings.about} />

                  <Link to="/pp" style={{textDecoration: "underline"}}>
                    Privacy policy
                  </Link>
                  
                </Container>

                <br/>
              <br/>

                <a href="mailto:laura@lauralemaitre.be" target="_blank">
                    <div style={{textAlign: "center", padding: "10px", color: "white", backgroundColor: "black"}}>
                    <div className="Title" style={{color:"white"}}>
                      MAIL
                  </div>
                  </div>
              </a> 

              <br/>

              <a href="https://www.instagram.com/lauralemaitre_/">
                    <div style={{textAlign: "center", padding:"10px", color: "white", backgroundColor: "black"}}>
                    <div className="Title" style={{color:"white"}}>
                      INSTAGRAM
                  </div>
                  </div>
              </a> 

              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>

              
                </div>
                
            </OnlyMobile>
            
    
    </>
  }

export default About;