    import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row, Text, SubTitle, Button, NotMobile, OnlyMobile, Title} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';
import PhotoBanner from './PhotoBanner';
import { Link } from 'react-router-dom';

const Lookbook = ()=>{
    const settings = useSelector(state => state.data.settings);

    useEffect(()=>{
        
    })

    if (!settings){
        return null;
    }


    let array = [];

    for (let i  = 0; i < 100; i++){
        array.push(i)
    }


    return <div style={{backgroundColor: "black"}}>
    
    
    <MetaTags>
                 <title>{ `LAURA LEMAITRE - lookbook`}</title>
                 {/* <meta name="description" content={BlocktoPlainText(settings.intro)} /> */}
                <meta property="og:title" content={"LAURA LEMAITRE / Lookbook"} />
                {/* <meta property="og:image" content={settings.url+"?w=720"} />   */}
            </MetaTags>
            <NotMobile>
                <PhotoBanner imgs={settings.lookbook.map(i=>i.img)} fh />
            </NotMobile>
            <OnlyMobile >
                <div className="azerty" style={{padding: "10px", backgroundColor: "black"}}> 
                {settings.lookbook.map((img, i)=><img key={"mobile-"+i} src={img.img} style={{width: "100%", marginBottom: "8px"}}  />)}
                <Link to="/">
                    <div style={{textAlign: "center", color: "white"}}>
                    <div className="Title" style={{color:"white"}}>
                      Home
                  </div>
                  </div>
              </Link> 
                </div>
            </OnlyMobile>
            
                
            
    </div>
  }

  export default Lookbook;